import { TProductCompare } from "@ecp-redux/dto/productCompare.type";

import { API, api } from "./api";

export const productCompare = api.injectEndpoints({
  endpoints: (builder) => ({
    getProductCompare: builder.query<TProductCompare, void>({
      query: () => {
        return {
          url: API.getProductCompare.endpoint(),
          method: "GET",
        };
      },
      providesTags: [
        "PRODUCT_COMPARE",
        "PORTAL_USER_AUTH",
        "LANGUAGE",
        "CURRENCY_DROPDOWN",
      ],
    }),
    postAddProductCompare: builder.mutation<TProductCompare, string>({
      query: (sku: string) => {
        return {
          url: API.postAddProductCompare.endpoint(),
          method: "POST",
          body: {
            sku,
          },
        };
      },
      invalidatesTags: [
        "PRODUCT_COMPARE",
        "SEARCH_RESULTS",
        "PRODUCT_SLIDER_PRODUCTS",
      ],
    }),
    putRemoveProductCompare: builder.mutation<TProductCompare, string>({
      query: (sku: string) => {
        return {
          url: API.putRemoveProductCompare.endpoint(),
          method: "PUT",
          body: {
            sku,
          },
        };
      },
      invalidatesTags: [
        "PRODUCT_COMPARE",
        "SEARCH_RESULTS",
        "PRODUCT_SLIDER_PRODUCTS",
      ],
    }),
  }),
});

export const {
  useGetProductCompareQuery,
  usePostAddProductCompareMutation,
  usePutRemoveProductCompareMutation,
} = productCompare;

export const {
  getProductCompare,
  postAddProductCompare,
  putRemoveProductCompare,
} = productCompare.endpoints;
